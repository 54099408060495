import request from '../util/request'

//返回岗位类别
export function GetPostType(data) {
    return request({
        url: '/api/XmhHr/ReturnPostType',
        method: 'get',
        params : data
    })
}

//返回招聘岗位
export function GetPostList(data) {
    return request({
        url: '/api/XmhHr/GetNeedsPlan',
        method: 'post',
        data : data
    })
}

//返回招聘岗位详情
export function GetPostDetail(params) {
    return request({
        url: '/api/XmhHr/ReturnPostDetail',
        method: 'post',
        params
    })
}

//提交面试信息
export function SubmitData(data) {
    return request({
        url: '/api/XmhHr/SubmitInterviewData',
        data: data,
        method: 'post',
    })
}

//获取你问我答问题
export function GetHrQuestions(data){
    return request({
        url: '/api/XmhHr/GetHrQuestions',
        method: 'get',
        params : data
    })
}
//获取入职小测试问答题
export function GetInductionQu(data){
    return request({
        url: '/api/XmhHr/GetInductionQu',
        method: 'get',
        params : data
    })
}
//提交入职小测试答案
export function SubmitQuAnswer(data){
    return request({
        url: '/api/XmhHr/SubmitQuAnswer',
        method: 'post',
        data : data
    })
}