<template>
  <div class="container">
    <div class="main">
      <div class="title">
        <p class="postName">{{ params.name }}</p>
        <!--        <p class="salary">8K-12K</p>-->
      </div>
      <div class="item">
        <h2 class="info">职位描述</h2>
        <div class="content">
          <div class="items">
            <h4 class="itemTitle">岗位职责</h4>
            <div class="con" v-html="params.duty"></div>
          </div>
          <div class="items">
            <h4 class="itemTitle">职位要求</h4>
            <div class="con" v-html="params.dec"></div>
          </div>
        </div>
      </div>
      <div class="item">
        <h2 class="info">工作地点</h2>
        <div class="place">
          <span @click="handlePlace(item,index)" :class="{active: activeIndex === index}" v-for="(item,index) in params.work_place_name">{{item}}</span>
        </div>
        <div class="detailPlace">详细地址：{{detailPlace}}</div>
      </div>
    </div>
    <div class="operate">
      <p class="btn share" @click="$store.dispatch('changeShare',true)">分享</p>
      <p class="btn enter" @click="Application">应聘岗位</p>
    </div>
  </div>
</template>

<script>
import {GetUserStatus} from "@/api/base";
import {GetPostDetail} from "@/api/interview";

export default {
  name: "PostDetail",
  created() {
    this.$store.dispatch('changeLocation', '职位详情')
    console.log('this.$route.query>>>>', this.$route.query.id)
    this.id = this.$route.query.id
    if (!this.id) {
      this.$toast("页面失效,请从招聘列表重新进入此页面")
      return
    }
    this.fetchData()
  },
  data() {
    return {
      activeIndex: 0,
      id: "",
      detailPlace: "",
      params:{}
    }
  },
  methods: {
    handlePlace(item,index){
      this.activeIndex = index;
      const obj = {
        '总部智慧园区': '临西十二路和聚才六路交汇新明辉智慧物流园区1号楼（有班车、餐厅）',
        '金泉办公区': '清河北路与祝丘路交汇北100米蜜欢购物广场东门4楼（老金泉）',
        '工业园区': '兰山区前洞门村1004号（马厂湖以北）',
        '城建办公区': '通达路与北园路交汇城建时代广场13楼1335',
        '成都办公区': '西部智谷D区7栋2单元806'
      }
      this.detailPlace = obj[item];
    },
    fetchData() {
      GetPostDetail({id:this.id}).then(res => {
        if(res.row !== null){
          this.params = res.row;
          this.params.work_place_name = res.row.work_place_name.split(' ').filter(item => item);
          this.handlePlace(this.params.work_place_name[0],0)
        }
      })
    },
    async Application() {
      let res = await GetUserStatus({openId: this.$store.state.info.openId})
      if (!(res.row == null && res.errcode == 2)) {
        this.$toast('已提交面试资料')
        return
      }
      this.$router.push({
        name: 'Interview',
        query: {
          id: this.params.id,
          post: this.params.name
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.container {
  padding-bottom: 80px;

  .main {
    width: 355px;
    margin: 10px auto 0;
    background-color: #ffffff;
    box-shadow: 0px 1px 7px 0px rgba(153, 153, 153, 0.25);
    box-sizing: border-box;
    padding: 22px 13px 10px;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 25px;

      .postName {
        font-family: PingFang-SC-Bold;
        font-size: 18px;
        font-weight: 700;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: 0px;
        color: #333333;
      }

      .salary {
        font-family: PingFang-SC-Bold;
        font-size: 16px;
        font-weight: 700;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: 0px;
        color: #ff0006;
      }
    }

    .item {
      padding-bottom: 10px;

      .info {
        font-family: PingFang-SC-Bold;
        font-size: 16px;
        font-weight: 700;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: 0px;
        color: #333333;
        padding-left: 10px;
        position: relative;
        padding-bottom: 14px;
        border-bottom: 1px solid #ebebeb;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: -1;
          width: 4px;
          height: 15px;
          background-color: #02dbca;
          border-radius: 2px;
        }
      }

      .place {
        margin-top: 15px;
        font-size: 13px;
        span{
          margin-right: 8px;
          &.active{
            color: #1989fa;
            font-weight: 700;
          }
        }
      }
      .detailPlace{
        font-size: 13px;
        margin-top: 9px;
      }

      .content {
        padding-bottom: 10px;

        .items {

          &:first-child {
            padding-top: 17px;
            padding-bottom: 17px;
          }

          .itemTitle {
            font-family: PingFang-SC-Bold;
            font-size: 14px;
            font-weight: 700;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: 0px;
            color: #333333;
          }

          .con {
            margin-top: 12px;
            font-size: 13px;
          }
        }
      }
    }
  }

  .operate {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 13px;
    justify-content: space-between;
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 60px;
    left: 0;
    background-color: #fff;

    .btn {
      width: 168px;
      height: 40px;
      border-radius: 6px;
      box-sizing: border-box;
      border: solid 1px #02dbca;
      font-family: PingFang-SC-Medium;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .share {
      background-color: #ffffff;
      color: #02dbca;
    }

    .enter {
      background-color: #02dbca;
      color: #ffffff;
    }
  }
}


p, h2, div, h4 {
  margin: 0;
}
</style>
